<template>
  <div class="counter-component">
    <div class="counter-btn" @click="minus"> - </div>
    <div class="counter-show">
      <input type="text" v-model="number" v-on:input="fixNumber" @change="fixNumber">
    </div>
    <div class="counter-btn" @click="add"> + </div>
  </div>
</template>

<script>
export default {
  name: "NumberBox",
  props: {
    max: {
      type: Number,
      default: 5
    },
    min: {
      type: Number,
      default: 1
    },
    defaultVal:{
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      number: this.defaultVal == 0 ? this.min : this.defaultVal,
    }
  },
  watch: {
    number () {
      this.$emit('on-change', this.number)
      this.$emit('input', this.number)
      this.$emit('update');
    },
    defaultVal(){
      this.number = this.defaultVal == 0 ? this.min : this.defaultVal
    }
  },
  methods: {
    fixNumber () {
      let fix
      if (typeof this.number === 'string') {
        fix = Number(this.number.replace(/\D/g, ''))
      }
      else {
        fix = this.number
      }
      if (fix > this.max || fix < this.min) {
        fix = this.min
      }
      this.number = fix
    },
    minus () {
      if (this.number <= this.min) {
        return
      }
      this.number --
    },
    add () {
      if (this.number >= this.max) {
        return
      }
      this.number ++
    },
  }
}
</script>

<style scoped>
.counter-component {
  position: relative;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
}
.counter-show {
  float: left;
}
.counter-show input {
  border: none;
  border-top: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
  height: 28px;
  line-height: 28px;
  outline: none;
  text-indent: 4px;
}
.counter-btn {
  border: 1px solid #e3e3e3;
  float: left;
  height: 28px;
  line-height: 28px;
  width: 28px;
  text-align: center;
  cursor: pointer;
  font-size: 16px;

  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none;  /*webkit浏览器*/
  -ms-user-select: none;   /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  user-select: none;
}
.counter-btn:hover {
  border-color: #f42424;
  background: #f42424;
  color: #fff;
}
</style>
